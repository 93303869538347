// @flow
/**
 * Central configuration file
 *
 * This files program constant was originally created to provide a central source of truth for easy look up and editing of program configuration only relevant to the client. It has grown to include configuration which either began as an edge case or was unable to be added to the back-end due to time constraints.
 * I recommend overlooking the current values in this file, moving standarized options to the back-end (editable via the portal), and leaving only minimal edge cases.
 * Ideally with program configuration moved to the portal a single set of program of dynamic routes could be used (defined under a 'slug' property in the portal) and all data could be fetched from the back-end API. e.g. `/:slug/check`. This would result in very fast program stand up at the cost of customization.
 *
 * @author Barry Hood
 * @module constants
 */

/**
 * Replace "programName" with name of the program
 * Replace "pageName" with name of the page
 *   Possible pages are "check" (/check) and "claim" (/eligible)
 *
 * @typedef {object} ProgramsList
 * @property {object} [programName]
 * @property {string} programName.title - Prefix used in the title tags of programs pages
 * @property {string} programName.portalName - Name of the program in the utility portal. Used to fetch program data.
 * @property {string} programName.portalId - Id of the program in the utility portal. Used to fetch program data.
 * @property {array<string>} [programName.logos] - Paths of logos to be displayed in navbar of programs pages
 *
 * The below propties are only required if the program uses
 * basic/generic form components. E.g. "BasicFormCheck.js" or "BasicFormEligible.js"
 * @property {string} [programName.redirectPath] - redirect path after successfull form submission
 * @property {string} [programName.redirectPath.check] - redirection path for the check form
 * @property {string} [programName.redirectPath.eligible] - redirection path for the eligible form
 * @property {string} [programName.city]
 * @property {string} [programName.state]
 * @property {string} [programName.terms] - HTML of programs terms text content. Displayed on eligibility form.
 * @property {object} [programName.accountNumberRules]
 * @property {object|number} [programName.accountNumberRules.length]
 * @property {number} [programName.accountNumberRules.length.min]
 * @property {number} [programName.accountNumberRules.length.max]
 * @property {number} [programName.accountNumberRules.length.helpText] - incase account number includes non numeric characters
 * @property {string} [programName.accountNumberRules.label]
 * @property {object} [programName.placeholder]
 * @property {string} [programName.placeholder.accountNumber]
 * @property {string} [programName.placeholder.phone]
 * @property {string} [programName.placeholder.zip]
 * @property {string} [programName.placeholder.email]
 * @property {boolean} [programName.proInstallationOnly]
 * @property {boolean} [programName.maxSprinklerZones]
 * @property {object} [programName.drip]
 * @property {object} [programName.drip.pageName]
 * @property {string} programName.drip.pageName.campaignId
 * @property {object} [programName.drip.pageName.tags]
 * @property {string} [programName.drip.pageName.tags.base]
 * @property {string} [programName.drip.pageName.tags.ineligible]
 * @property {string} [programName.drip.pageName.tags.eligible]
 * @property {object} [programName.zapier]
 * @property {string} [programName.zapier.pageName]
 * @property {string} [programName.klaviyo] - klaviyo list id (for discount programs)
 */

import outdoorEnclosure from "../../static/img/product-outdoor-enclosure.png"
import smartHosetimerImg from "../../static/img/product-smart-hose-timer.png"
// import outdoorEnclosure from './assets/product-outdoor-enclosure.png'
import productImage from '../../static/img/product-rachio3-phone.png'
// import productImage from './assets/product-rachio3-phone.png'

export const isProdOrStaging = process.env.ENVIRONMENT === 'production' ||process.env.ENVIRONMENT === 'staging'
export const isProd =  process.env.ENVIRONMENT === 'production'
export const isDev = process.env.ENVIRONMENT === 'development'

export const sprinklerZones = {
  '1-8': '1-8',
  '9-16': '9-16',
  '17-24': '17-24',
  '25-32': '25-32',
}
/**
 * Configuration of programs
 * @type {ProgramsList}
 * @const
 */
export const programs = {
  rancho: {
    title: 'Rancho California Water District',
    logos: ['/img/rancho/valleyscape_logo.png'],
    portalName: 'RANCHO - Pilot',
    portalId: isProdOrStaging
      ? '98dbe64d-c09b-4d70-bc25-3001c50e1723'
      : '98dbe64d-c09b-4d70-bc25-3001c50e1723',
    proInstallationOnly: true,
    accountNumberRules: {
      length: 8,
    },
    city: 'Temecula',
    state: 'CA',
    placeholder: {
      accountNumber: '12345678',
      phone: '951-111-1111',
      zip: '92590',
    },
    drip: {
      check: {
        campaignId: '331281604',
        base: [],
        ineligible: [],
        eligible: ['rancho', 'rancho-pilot'],
      },
      claim: {
        campaignId: '908848194',
      },
    },
    // Missing zapier data
  },
  roundrock: {
    title: 'City of Round Rock',
    logos: ['/img/roundrock/roundrock_logo.png'],
    portalName: 'RR - Pilot',
    portalId: isProdOrStaging
      ? 'e8f75a85-e466-477e-a701-f2e34e21094e'
      : 'e8f75a85-e466-477e-a701-f2e34e21094e',
    redirectPath: {
      eligible: '/success',
    },
    proInstallationOnly: false,
    accountNumberRules: {
      length: 6,
    },
    city: 'Round Rock',
    state: 'TX',
    placeholder: {
      accountNumber: '123456',
      phone: '512-111-1111',
      zip: '78664',
    },
    drip: {
      check: {
        campaignId: '378434518',
        tags: {
          base: [],
          ineligible: [],
          eligible: ['roundrock', 'rr-pilot'],
        },
      },
      claim: {
        campaignId: '310208597',
      },
    },
    zapier: {
      check: '3249827/lx3qw9',
      claim: '3249827/lx309i',
    },
  },
  rwc: {
    title: 'Redwood City',
    logos: ['/img/rwc/rwc_logo.png', '/img/rwc/sgcb_logo.png'],
    portalId: isProdOrStaging
      ? 'da8cc986-69a0-431f-8625-f74ead2bdee7'
      : 'da8cc986-69a0-431f-8625-f74ead2bdee7',
    city: 'Redwood City',
    state: 'CA',
  },
  beta: {
    title: 'Beta Application',
    portalId: isProdOrStaging ? '' : '',
  },
  pro: {
    title: 'Rachio Certified Pro',
    logos: ['/img/pro/rachiopro.png'],
    portalId: isProdOrStaging ? '' : '',
  },
  /**
   * Discount programs
   */
  amazon: {
    title: 'Amazon',
    logos: ['/img/discounts/amazon_logo.png'],
    isDiscountProgram: true,
    klaviyo: 'L77dzU',
    placeholder: {
      email: 'amazon.com',
    },
    emailRegex:
      'amazon.com|audible.com|wholefoodsmarket.com|ring.com|zappos.com',
  },
  newbelgium: {
    title: 'New Belgium',
    logos: ['/img/discounts/new_belgium_logo.png'],
    isDiscountProgram: true,
    klaviyo: 'NcdeMT',
    placeholder: {
      email: 'newbelgium.com',
    },
    emailRegex: 'newbelgium.com',
  },
  homeadvisor: {
    title: 'HomeAdvisor',
    logos: ['/img/discounts/homeadvisor_logo.png'],
    isDiscountProgram: true,
    klaviyo: 'PqkWus',
    placeholder: {
      email: 'homeadvisor.com',
    },
    emailRegex: 'homeadvisor.com',
  },
  alarm: {
    title: 'Alarm.com',
    logos: ['/img/discounts/alarm_logo.png'],
    isDiscountProgram: true,
    klaviyo: 'HxsLnH',
    placeholder: {
      email: 'alarm.com',
    },
    emailRegex: 'alarm.com',
  },
  discount: {
    title: 'Discount',
    isDiscountProgram: true,
  },
  /**
   * Static programs
   */
  oc: {
    title: 'Orange County',
    logos: ['/img/partner/valley_soil_logo.png'],
    city: 'Southern California',
    state: 'CA',
  },
  srp: {
    title: 'Salt River Project',
    logos: ['/img/srp/srp_logo_compressed.jpg', '/img/partner/ewing_logo.png'],
    isDiscountProgram: true,
    contactInfo: {
      phoneNumber: '(602) 737-1440',
      email: 'srp@rachio.com',
    },
  },
}
/**
 * Shipstation order item types
 * @type {Object}
 * @const
 */
export const shipstationItems = {
  GEN3_8ZONE_W_STICKER: {
    lineItemKey: 'gen3-8-inst',
    sku: '8ZULW-C-INSTL',
    name: 'Rachio 3 8 Zone w/ Install',
    weightInOunces: 64,
    productId: 18983251,
    fulfillmentSku: '8ZULW-C',
  },

  GEN3_16ZONE_W_STICKER: {
    lineItemKey: 'gen3-16-inst',
    sku: '16ZULW-C-INSTL',
    name: 'Rachio 3 16 Zone w/ Install',
    weightInOunces: 64,
    productId: 18983419,
    fulfillmentSku: '16ZULW-C',
  },

  GEN3_8ZONE: {
    lineItemKey: 'gen3-8',
    sku: '8ZULW-C',
    name: 'Rachio 3 8 Zone Controller',
    weightInOunces: 64,
    productId: 12127824,
    fulfillmentSku: '8ZULW-C',
  },

  GEN3_16ZONE: {
    lineItemKey: 'gen3-16',
    sku: '16ZULW-C',
    name: 'Rachio 3 16 Zone Controller',
    weightInOunces: 64,
    productId: 12128532,
    fulfillmentSku: '16ZULW-C',
  },

  OUTDOOR_ENCLOSURE: {
    lineItemKey: 'encl',
    sku: '16RACHBX',
    name: 'Rachio Outdoor Enclosure',
    weightInOunces: 48,
    productId: 12128535,
    fulfillmentSku: '16RACHBX',
  },
}

// CARVERCOUNTY PROGRAM ITEMS ///////////////////////////////////////////////////////////////

export const programItems = (price: number, samePrice: boolean) => [
  {
    id: '4ZULW-C',
    itemType: 'Smart Sprinkler Controller',
    itemName: '<strong>Rachio 3</strong> <br/> 4 Zone',
    origPrice: 149.99,
    salePrice: !samePrice && price > 0 ? (price - 3000) / 100 : price / 100,
    quanityMax: true,
    name: 'quantity4Zone',
    image: productImage,
    variantId: 40050357928024,
    sprinklerZones: 4,
  },
  {
    //old id blue box 8ZULW-C
    //old variantId 20572612296792
    id: '8ZULW-C',
    itemType: 'Smart Sprinkler Controller',
    itemName: '<strong>Rachio 3</strong> <br/> 8 Zone',
    origPrice: 199.99,
    salePrice: price / 100,
    quanityMax: true,
    name: 'quantity8Zone',
    image: productImage,
    variantId: 20572612296792,
    sprinklerZones: 8,
  },
  {
    //old id blue box 16ZULW-C
    //old variantId 20572612362328
    id: '16ZULW-C',
    itemType: 'Smart Sprinkler Controller',
    itemName: '<strong>Rachio 3</strong> <br/> 16 Zone',
    origPrice: 249.99,
    salePrice: !samePrice && price > 0 ? (price + 2000) / 100 : price / 100,
    quanityMax: true,
    name: 'quantity16Zone',
    image: productImage,
    variantId: 20572612362328,
    sprinklerZones: 16,
  },
  {
    id: '16RACHBX',
    itemType: 'Accessory',
    itemName: 'Weatherproof Enclosure',
    origPrice: 29.99,
    salePrice: 29.99,
    quanityMax: false,
    name: 'quantityOutdoorEnclosure',
    image: outdoorEnclosure,
    variantId: 20572612231256,
    sprinklerZones: 0,
  },
  {
    id: 'SHVK001',
    itemType: 'Smart Sprinkler Controller',
    itemName: '<strong>Smart Hose Timer</strong> <br/> Kit + Valve',
    origPrice: 99.99,
    salePrice: 89.99,
    quanityMax: false,
    name: 'quantitySmartHosetimer',
    image: smartHosetimerImg,
    variantId: 41361359274072,
    sprinklerZones: 0,
  }
]

export type DiscountCode =
  | 'GENERATEONETIMEDISC87E4IF6'
  | 'GENERATEONETIMEDISCAL74D6M'
  | ''
export type Quantity8Zone = 1 | 0
export type Quantity16Zone = 1 | 0

export type Discount = {
  items: {
    quantity8Zone: Quantity8Zone,
    quantity16Zone: Quantity16Zone,
  },
  code: DiscountCode,
}

// CARVERCOUNTY DISCOUNT CODES CREATED IN RACHIO-UTILITY SHOPIFY STORE ///////////////////////

export const discounts = {
  '0': {
    '0': 'GENERATEONETIMEDISCD94UPWW', // one 16 zone 279.00 disc -> 249
    '1': 'GENERATEONETIMEDISCHKH334T', // one 8 zone 229.00 disc -> 199
    '2': 'GENERATEONETIMEDISCFNJ0917', // one 2 zone 149.00 disc -> 149
  },
  '4999':{
    '0': 'GENERATEONETIMEDISC080210', // one 16 zone 200.00 disc
    '1': 'GENERATEONETIMEDISC000180', //one 8 zone 180.00 disc
    '2': '', //No 4 zone discount
  },
  '6999': {
    '0': 'GENERATEONETIMEDISCRHD3LP2', // one 16 zone 190.00 disc
    '1': 'GENERATEONETIMEDISCRH3DLP2', // one 8 zone 160.00 disc
    '2': 'GENERATEONETIMEDISC4TRS9ME', // one 2 zone 110.00 disc
  },
  '7499': {
    '0': "GENERATEONETIMEDISCAL74D6M", 
    '1': "GENERATEONETIMEDISC87E4IF6"
  },
  '8500': {
    '0': "GENERATEONETIMEDISC0000194", 
    '1': "GENERATEONETIMEDISC0000144", 
    '2': "GENERATEONETIMEDISC04008499"
  },
  '8999': {
    '0': 'GENERATEONETIMEDISCGA6RFDFTZ0BE', // one 16 zone 190.00 disc
    '1': 'GENERATEONETIMEDISCXWXRMP5ZPSKS', // one 8 zone 140.00 disc
    '2': 'GENERATEONETIMEDISC4TRS9ME', // one 2 zone 110.00 disc
  },
  '9499': {
    '0': 'GENERATEONETIMEDISC280A6V8', // one 16 zone 165.00 disc
    '1': 'GENERATEONETIMEDISC2A086V8', // one 8 zone 135.00 disc
    '2': 'GENERATEONETIMEDISC3PY5WZY', // one 2 zone 85.00 disc
  },
  '9999': {
    '0': 'GENERATEONETIMEDISC000160', // one 16 zone 160.00 disc -- this 170.00 disc GENERATEONETIMEDISC57SCNJM
    '1': 'GENERATEONETIMEDISC6Yl44GF', // one 8 zone 130.00 disc
    '2': 'GENERATEONETIMEDISCKP3R4GD', // one 2 zone 80.00 disc
  },
  '10000': {
    '0': "GENERATEONETIMEDISCMAH791W", 
    '1': "GENERATEONETIMEDISC8Q0ORY4", 
    '2': "GENERATEONETIMEDISC04008499"
  },
  '11999': {
    '0': 'GENERATEONETIMEDISC4SRT9ME', // one 16 zone 140.00 disc
    '1': 'GENERATEONETIMEDISC4TRS9ME', // one 8 zone 110.00 disc
    '2': 'GENERATEONETIMEDISC6YO4GDE', // one 2 zone 60.00 disc
  },
  '12999': {
    '0': 'GENERATEONETIMEDISC000130', //one 16 zone 130.00 disc
    '1': 'GENERATEONETIMEDISC000100', //one 8 zone 100.00 disc
    '2': '',//No discount for 4zone
  },
  '14997': {
    '0': 'GENERATEONETIMEDISC16CCMA9', // one 16 zone 110.00 disc
    '1': 'GENERATEONETIMEDISC8CCYUI', // one 8 zone 80.00 disc
    '2': 'GENERATEONETIMEDISCD4DCD4Z', // one 2 zone 30.00 disc
  },
  '14999': {
    '0': 'GENERATEONETIMEDISC000110', // one 16 zone 110.00 disc
    '1': 'GENERATEONETIMEDISC000080', // one 8 zone 80.00 disc
    '2': 'GENERATEONETIMEDISC000030', // one 2 zone 30.00 disc
  },
  '16999': {
    '0': 'GENERATEONETIMEDISC6YO4GED', // one 16 zone 90.00 disc
    '1': 'GENERATEONETIMEDISC6YO4GDE', // one 8 zone 60.00 disc
    '2': 'GENERATEONETIMEDISCD4DCD4Z', // one 2 zone 10.00 disc
  },
}

export const terms: Array<string> = [
  'This offer is non-transferable. The Rachio Smart Sprinkler Controller must be installed at the property associated with the account that received the offer.',
  'I agree to install my new Smart Controller within 2 months of the date I receive it.',
  'The premise has an existing, functioning in-ground irrigation system, containing no more than 16zones, which is currently operated by a functioning non-weather based controller.',
  'The premise has reliable Wi-Fi able to provide a good signal to the irrigation controller component of the Smart Water System to be installed. Wi-Fi shall remain activated at all times.',
  'I am a homeowner of record of the subject property.',
]

export type EligibleProgram = {
  id: string,
  muniId: string,
  name: string,
  distributionLimit: number,
  termsLink: string,
  status: string,
  auditRequirement: boolean,
  installationRequirement: boolean,
  budget: number,
  price: number,
  chargeStatementDescriptor: string,
  created: string,
  updated: string,
  residentsCount: number,
  residentsRedeemedCount: number,
  unitsLeftCount: number,
  flowMeterIncluded: boolean,
  slug: string,
  programCity: string,
  programState: string,
  programPhoneNumber: string,
  shippingId: string,
  schedulingRequired: boolean,
  maxSprinklerZonesAllowed: number,
  muniName: string,
  muniWebsite: string,
  residentsWithEmailCount: number,
}

